<template>
  <div v-if="!isLoading" class="DropzoneText">
    <slot>Ajouter un document</slot>
  </div>
</template>

<script setup lang="ts">
const isLoading = inject<boolean>("isLoading");
</script>

<style scoped lang="scss">
.DropzoneText {
  color: var(--gray-4);
}
</style>
